import request from '@/utils/request'

//首页汇总信息
export function getSummaryInfo() {
	return request({
		url: `/thys-api/contribute/home/summary`,
		method: 'get'
	})
}
// 用户端-新增投稿
export function newManuscript(data) {
	return request({
		url: `/thys-api/contribute/manage/add`,
		method: 'post',
		data: data
	})
}
// 投稿列表
export function contributeList(data) {
	return request({
		url: `/thys-api/contribute/manage/list`,
		method: 'post',
		data: data
	})
}
// 下载/预览文件,返回二进制文件流
export function downloadFile(id) {
  return request({
    url: '/system/file/preview',
    method: 'get',
    params: {id:id},
    responseType: 'blob'
  })
}
// 用户端-稿件文件修改
export function updateManuscript(data) {
	return request({
		url: `/thys-api/contribute/manage/file/update`,
		method: 'put',
		data: data
	})
}
// 用户端-同意修改
export function agreeEdit(id) {
	return request({
		url: `/thys-api/contribute/manage/agree/modify/${id}`,
		method: 'put',
		header: {
      'content-type': 'application/x-www-form-urlencoded',
    }
	})
}
// 用户端-最终确认
export function finalConfirm(id) {
	return request({
		url: `/thys-api/contribute/manage/confirm/${id}`,
		method: 'put',
		header: {
      'content-type': 'application/x-www-form-urlencoded',
    }
	})
}
// 稿酬管理列表
export function feeList(data) {
	return request({
		url: `/thys-api/contribute/fee/page`,
		method: 'post',
		data: data
	})
}
//根据投稿Id获取证书信息
export function getCertificateInfo(id) {
	return request({
		url: `/thys-api/contribute/manage/certificate/${id}`,
		method: 'get',
		header: {
      'content-type': 'application/x-www-form-urlencoded',
    }
	})
}
//是否可以新增 true 为可以新增
export function isCanNewAdd(data) {
	return request({
		url: `/thys-api/contribute/manage/judge/add`,
		method: 'get',
		header: {
      'content-type': 'application/x-www-form-urlencoded',
    },
		data: data
	})
}