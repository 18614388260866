import request from '@/utils/request'

//首页汇总信息
export function getSummaryInfo() {
	return request({
		url: `/thys-api/contribute/home/summary`,
		method: 'get'
	})
}
// 平台端-拒稿-修回
export function fallBackManuscript(data) {
	return request({
		url: `/thys-api/contribute/manage/fallback`,
		method: 'put',
		data: data
	})
}
// 投稿列表
export function contributeList(data) {
	return request({
		url: `/thys-api/contribute/manage/list`,
		method: 'post',
		data: data
	})
}
// 平台端-接收稿件
export function receiveManuscript(data) {
	return request({
		url: `/thys-api/contribute/manage/receive`,
		method: 'put',
		data: data
	})
}
// 平台端-批量确认支付
export function batchAckFee(data) {
	return request({
		url: `/thys-api/contribute/fee/batch/ack`,
		method: 'put',
		data: data
	})
}
// 平台端-撤销支付
export function rollbackFee(id) {
	return request({
		url: `/thys-api/contribute/fee/rollback/${id}`,
		method: 'put',
		header: {
      'content-type': 'application/x-www-form-urlencoded',
    }
	})
}
// 平台端-排版完成
export function finishLayout(params) {
	return request({
		url: `/thys-api/contribute/manage/layout/finish`,
		method: 'put',
		data: params,
		header: {
      'content-type': 'application/x-www-form-urlencoded',
    }
	})
}
// 平台端-更新预发布链接
export function preLink(params) {
	return request({
		url: `/thys-api/contribute/manage/pre/link`,
		method: 'put',
		data: params,
		header: {
      'content-type': 'application/x-www-form-urlencoded',
    }
	})
}
// 平台端-更新预发布时间
export function preDate(params) {
	return request({
		url: `/thys-api/contribute/manage/pre/date`,
		method: 'put',
		data: params,
		header: {
      'content-type': 'application/x-www-form-urlencoded',
    }
	})
}
// 平台端-平台稿件发布
export function publish(params) {
	return request({
		url: `/thys-api/contribute/manage/publish`,
		method: 'put',
		data: params,
		header: {
      'content-type': 'application/x-www-form-urlencoded',
    }
	})
}
//结算月份列表
export function getMonthList() {
	return request({
		url: `/thys-api/contribute/fee/month/list`,
		method: 'get'
	})
}
//导出excel
export function uploadExcel(month) {
	return request({
		url: `/thys-api/contribute/fee/export/${month}`,
		method: 'get',
		resopnseReturn: true,
    responseType: 'blob'
	})
}
//公众号账号配置查询
export function getSetupConfig(data) {
	return request({
		url: `/thys-api/contribute/account/config`,
		header: {
      'content-type': 'application/x-www-form-urlencoded',
    },
		method: 'get',
		params: data
	})
}
//公众号账号配置修改
export function editSetupConfig(params) {
	return request({
		url: `/thys-api/contribute/account/config`,
		method: 'put',
		data: params
	})
}
//稿件信息
export function feeInfo(id) {
	return request({
		url: `/thys-api/contribute/manage/info/${id}`,
		method: 'get'
	})
}
//编辑稿酬
export function editFee(params) {
	return request({
		url: `/thys-api/contribute/manage/edit/fee/${params.id}?fee=${params.fee}`,
		method: 'put',
		header: {
      'content-type': 'application/x-www-form-urlencoded',
    },
		data: `fee=${params.fee}`
	})
}
// 稿件作废
export function abandonedScript(id) {
	return request({
		url: `/thys-api/contribute/manage/abandoned/${id}`,
		method: 'put',
		header: {
      'content-type': 'application/x-www-form-urlencoded',
    }
	})
}
// 稿件作废
export function recoveryScript(id) {
	return request({
		url: `/thys-api/contribute/manage/recovery/${id}`,
		method: 'put',
		header: {
      'content-type': 'application/x-www-form-urlencoded',
    }
	})
}
// 平台端-编辑
export function editArticle(data) {
	return request({
		url: `/thys-api/contribute/manage/platform/edit`,
		method: 'post',
		data: data
	})
}
// 平台端-修改文章信息
export function updateArticleInfo(data) {
	return request({
		url: `/thys-api/contribute/manage/update/contribute/info`,
		method: 'post',
		data: data
	})
}